@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import "./reset.scss";
@import "./varibles.scss";
@import "./breakpoints.scss";
@import "./functions.scss";
@import "./components/header.scss";
@import "./components/banner.scss";
@import "./components/cases.scss";
@import "./components/intro-overlay.scss";
@import "./components/button.scss";

body,
html {
  visibility: hidden;
  user-select: none;
  background: $brand-dark;
  overscroll-behavior: none;
  margin: 0;
  font-size: $font-size;
  font-family: "Manrope", -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}


.main {
  z-index: 2;
  transform: translateY(0);
  position: relative;
}


.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;
  height: 100%;

  @include media("<=MDdesktop") {
    width: 1280px;
    min-width: 1280px;
  }

  @include media("<=desktop") {
    width: 1080px;
    min-width: 1080px;
  }

  @include media("<=tablet") {
    width: 100%;
    min-width: 100%;
  }

  .row {
    display: flex;
    height: 100%;
    padding: 0 32px;

    @include media("<=phone") {
      padding: 0 16px;
    }
  }

  .v-center {
    align-items: center;
  }

  .space-between {
    justify-content: space-between;
  }
}

.container-fluid {
  width: 100%;
  height: 100%;

  .row {
    display: flex;
    height: 100%;
  }
}