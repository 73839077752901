.btn {
  position: relative;
  font-size: rem(18);
  line-height: 1;
  color: $black;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 700;
  background-color: $white;
  padding: 18px 30px;
  border-radius: 50px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  border: 1px solid transparent;
  transition: 0.3s ease-out;
  width: 265px;

  @include media("<=tablet") {
    font-size: rem(16);
    padding: 16px 20px;
    width: 218px;
  }

  svg {
    position: absolute;
    margin: auto 0;
    top: 0;
    bottom: 0;
    right: 30px;
    width: 21px;
    height: 21px;

    @include media("<=tablet") {
      right: 20px;
      width: 18px;
      height: 18px;
    }
  
  }

  .hover-text {
    opacity: 0;
    visibility: hidden;
    font-size: 0;
    position: absolute;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.04);
    color: $white;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 300px;

    @include media("<=tablet") {
      width: 270px;
    }

    span {
      opacity: 0;
      visibility: hidden;
      font-size: 0;
      position: absolute;
    }

    .hover-text {
      display: flex;
      text-transform: lowercase;
      letter-spacing: 0.1em;
      font-size: rem(18);
      opacity: 1;
      position: relative;
      visibility: visible;
    }
  }
}