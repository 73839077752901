.banner {
  height: 50vh; //fallback option
  height: calc(var(--vh, 1vh) * 50);
  padding-top: 100px;
  padding-bottom: 50px;
  min-height: $banner-min-height;

  @include media("<=tablet") {
    min-height: 340px;
    padding-top: 75px;
    padding-bottom: 30px;
  }

  .row {
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: rem(80);
      line-height: 1;
      font-weight: 500;
      z-index: 10;
      color: $white;

      @include media("<=MDdesktop") {
        font-size: rem(60);
      }

      @include media("<=tablet") {
        font-size: rem(34)
      }

      .line {
        height: 80px;
        position: relative;
        overflow: hidden;

        @include media("<=MDdesktop") {
          height: 60px;
        }

        @include media("<=tablet") {
          height: 42px;
        }

        span {
          position: absolute;
        }
      }
    }

    .btn-row {
      position: relative;
      z-index: 2;
      justify-content: flex-start;
      display: flex;
      margin-top: 40px;

      @include media("<=tablet") {
        margin-top: 30px;
      }
    }
  }
}