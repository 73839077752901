.header {
  width: 100%;
  padding-top: 40px;
  z-index: 7;
  position: fixed;

  @include media("<=tablet") {
    padding-top: 30px;
  }

  .logo {
    display: flex;
    height: 30px;

    svg {
      height: 100%;
    }
  }
}