@function vw($px, $screen: desktop) {
    $screenSize: 1728;

    @if ($screen ==mobile) {
        $screenSize: 360;
    }

    @return calc((#{$px} / #{$screenSize}) * 100 * var(--vw, 1vw));
}

// Font-size pixels to rem conversion
@function rem($pixels, $context: $font-size) {
    @if unitless($pixels) {
      $pixels: $pixels * 1px;
    }
    @if unitless($context) {
      $context: $context * 1px;
    }
    @return $pixels / $context * 1rem;
  }