$font-size: 16px;

$black: #000000;
$white: #ffffff;
$brand-dark: #041519;
$brand-darker: #031215;
$brand-darker: #031013;


$banner-min-height: 440px;
$cases-min-height: 360px;