.cases {
  height: 50vh;
  height: calc(var(--vh, 1vh) * 50);
  min-height: $cases-min-height;

  .row {
    @include media("<=tablet") {
      flex-direction: column;
    }

    .case {
      position: relative;
      background: $black;

      &:hover {
        .case-image {
          opacity: 0.4;
        }
      }

      .case-details {
        width: 33.3333vw;
        height: 50vh;
        height: calc(var(--vh, 1vh) * 50);
        min-height: $cases-min-height;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 75px 50px;
        box-sizing: border-box;
        z-index: 1;
        position: relative;

        @include media("<=MDdesktop") {
          padding: 40px 32px;
        }

        @include media("<=tablet") {
          width: 100vw;
        }

        @include media("<=phone") {
          padding: 40px 16px;
        }

        p {
          margin-top: 30px;
          font-size: rem(18);
          line-height: rem(38);
          opacity: 1;
          color: $white;
          font-weight: 400;
          margin-bottom: 0;
          max-width: 480px;

          @include media("<=MDdesktop") {
            margin-top: 20px;
          }

          @include media("<=tablet") {
            font-size: rem(16);
            line-height: rem(28);
            margin-top: 10px;
          }
        }

        h2 {
          font-size: rem(38);
          line-height: rem(48);
          font-weight: 500;
          color: $white;
          margin-top: auto;
          margin-bottom: 0;
          max-width: 480px;

          @include media("<=MDdesktop") {
            font-size: rem(32);
            line-height: rem(42);
          }

          @include media("<=phone") {
            font-size: rem(28);
            line-height: rem(34);
          }
        }
      }

      .case-image {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        opacity: 0.6;
        transition: 0.4s cubic-bezier(0.6, -0.05, 0.1, 0.99);

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}